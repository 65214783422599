import React from 'react';
import {graphql} from 'gatsby';
import Layout from '../components/layout'

import Expandable from 'components/Expandable'
import BottomNavigation from 'components/BottomNavigation'
import {TitleBanner} from 'components/banners/titleBanner'
import {BgPattern} from 'components/backgrounds'
import BgStyles from 'components/backgrounds/patterns.module.css'


export const EthicsTemplate = ({data, ...props}) => (
  <Layout
    articleId={`${data.wordpressPage.type}-${data.wordpressPage.wordpress_id}`}
    articleClassName={`${data.wordpressPage.classes}`}
    context = {props.pageContext}
    location = {props.location}
    ancestors={data.wordpressPage.ancestors}
  >
    <div className="container px-5 mx-auto">
      <TitleBanner title={data.wordpressPage.title} />
      <div className="entry">
        <div className="container mx-auto entry-content" style={{marginTop: 140}}>
          <BgPattern className={`${BgStyles.pattern2} ${BgStyles.ethicsPattern}`}/>
        {data.wordpressPage.acf.ethics_items.map((item)=> (
          <Expandable {...item} />
        ))}
  </div>
      </div>
      <footer className="entry-footer">
        {data.wordpressPage.showPageGroupWidget && data.wordpressPage.siblingPages && data.wordpressPage.siblingPages.length > 0 &&
         <BottomNavigation items={data.wordpressPage.siblingPages} />
        }

      </footer>
    </div>
  </Layout>
)

export default EthicsTemplate
export const pageDefautQuery = graphql `
query EthicsPageById($id: String!){
  wordpressPage(id:{eq:$id}){
    title
    content
    path
    type
    date
    wordpress_id
    classes
showPageGroupWidget
    siblingPages {
      title
      excerpt
      path
    }

    acf {
      ethics_items {
        title
        content
        button:link {
          url
          target
          title
        }
      }
    
    }
  }
}
`
